
li {
  list-style: none;
}

.form-group.selected-row {
  background-color: #87E5E3;
}

.form-group.selected-row-beginning {
  background-color: #7FD8D7;
}

.transaction-list {
  margin-top: 10px;
  width: 70%;
  float: left;
  user-select: none;
}

.transaction-result {
  margin-top: 10px;
  width: 30%;
  float: left;
  position: relative;
}

.transaction-result > .card-fixed {
  margin: 0 10px;
  position: fixed;
}

.transaction-list > .card-form {
  margin-bottom: 15px;
}

.transaction-list .subgroup::before,
.transaction-list .group::before {
  opacity: 0;
}

.transaction-list .subgroup {
  width: 100%;
  box-shadow: 0 0 0;
  padding: 0 0 0 20px;
}

.transaction-list .container {
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}

.transaction-list .container:focus {
  outline: none;
}

.transaction-list .container.last {
  width: 100%;
  padding: 0;
  display: block;
}

.transaction-list .group {
  margin: 0;
}

.transaction-list .group li {
  width: 90%;
}

.transaction-list .form-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-direction: row;
  transition: all .25s ease-in-out;
  cursor: pointer;
}

.transaction-list .form-group:hover {
  background-color: #AEEDF1;
}

.transaction-list .form-group > div > p {
  line-height: 48px;
  float: left;
}
.transaction-list .form-group > div.left {
  margin-left: 70px;
}
.transaction-list .form-group > div.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.transaction-list .form-group > div.right {
  margin-right: 66px;
}

.transaction-list .form-control-label {
  flex-direction: row-reverse;
  justify-content: space-between;
  padding-right: 12px;
  padding-left: 90px;
}

.checkbox-label {
  display: block;
  width: 100%;
}

.transaction-list .parent-checkbox {
  margin-right: 10px;
}

.transaction-list .bold {
  font-weight: 500;
  font-size: 15px;
}

.transaction-list .group .panel-summary {
  min-height: inherit;
}


.transaction-list .group.subgroup .panel-summary p {
  color: #525252;
}

.transaction-list .panel-summary > div:first-child {
  margin: 0;
  justify-content: space-between;
}

.transaction-list .panel-summary div > p {
  float: left;
  line-height: 48px;
}
