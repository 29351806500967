.CustomFlag {
  display: flex;
  align-items: center;
}

.CustomFlag img {
  margin-right: 10px;
}

.CustomFlag span {
  font-size: initial;
}
