.edit {
  color:#7c7979;
  font-weight: 300;
  display: flex;
  align-items: center;
}

.edit p {
  margin-right: 10px;
}
