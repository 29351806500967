.pack3_parent {
  display: flex;
  flex-wrap: wrap;
}

.pack3_parent .child {
  display: inline-block;
  margin: 10px 0 0 10px;
  height: 500px;
  width: calc(100% * (1 / 7) - 10px - 1px);
}

.pack3_parent .child-week {
  display: inline-block;
  margin: 10px 0 0 10px;
  height: 75px;
  width: calc(100% * (1 / 7) - 10px - 1px);
}

.pack3_parent .child .shop_slider {
  display: inline-block;
  width: calc(100% * (1 / 2) - 2px);
  margin-right: 2px;
  background-color: lightskyblue;
  margin-top: 2px;
  /* border-radius: 0.5rem; */
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 50px;
}

.pack3_parent .child .shop_slider.empty {
  background-color: lightgray;
  height: 50px;
}

.pack3_parent .child .shop_slider .category_img {
  margin-top: 2px;
  max-width: 20px;
  max-height: 20px;
  width: auto;
  height: auto;
}

.pack3_parent .child .shop_slider .shop {
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: x-small;
  margin-top: 8px;
}
