.calendar_parent {
  display: flex;
  flex-wrap: wrap;
}

.calendar_parent .child {
  display: inline-block;
  margin: 10px 0 0 10px;
  height: 160px;
  width: calc(100% * (1 / 7) - 10px - 1px);
}

.calendar_parent .child-week {
  display: inline-block;
  margin: 10px 0 0 10px;
  height: 75px;
  width: calc(100% * (1 / 7) - 10px - 1px);
}

.calendar_parent .child .banner_label {
  background-color: lightskyblue;
  margin-top: 2px;
  border-radius: 0.5rem;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.calendar_parent .child .banner_label.empty {
  background-color: lightgreen;
}
