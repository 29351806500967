.cashback-history-container{
  display: flex;
  align-items: center;
  height: 30px;
}

.cashback-history-container div, .cashback-history-container h4{
  width: 50%;
  text-align: center;
  margin: 0;
}
.no-history{
  text-align: center;
  text-transform: uppercase;
  height: 30px;
}


  