.alert {
  margin: 24px 0;
  padding: 4px 24px;
  border-left: 5px solid #f5c6cb;
  background-color: #f8d7da;
  color: #721c24;
  line-height: 3rem;
}

.success {
  margin: 24px 0;
  padding: 4px 24px;
  border-left: 5px solid #c3e6cb;
  background-color: #d4edda;
  color: #155724;
  line-height: 3rem;
}

.loading {
  margin: 24px 0;
  padding: 4px 24px;
  border-color: 5px solid #d6d8db;
  background-color: #e2e3e5;
  color: #383d41;
  line-height: 3rem;
}
